import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#1F2937",
      }}
    >
      <img src="/UnderConstruction.png" width={"30%"} alt="UnderConstruction" />

      <Link to="/">
        <button
        className="custom-button"
          type="primary"
          style={{
            background: "#1F2937",
            color: "White",
            fontWeight: 600,
          }}
        >
          <span>Go back to home</span>
        </button>
      </Link>
    </div>
  );
};

export default Contact;
